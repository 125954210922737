<template lang="pug">
v-container
  v-card(width="800" min-height="200").mx-auto.mt-10
    v-card-title.headline.grey.lighten-2 Account Registration
    div.text-center.mx-auto.py-15(v-if="dataReady == false")
      v-progress-circular.mt-15(:size="70" :width="7" indeterminate color="primary")
      br
      br
    div(v-else-if="waiting==true").ma-7.pb-6
      v-alert(
        border="left"
        icon="mdi-check"
        color="grey"
        text
        ) <strong>Waiting for Account Validation.</strong> The form has been submitted. Please send an email with your Form 5/5a attached using your UP Mail Account to <a href="mailto: cslib@science.upd.edu.ph">cslib@science.upd.edu.ph</a>. Wait for the Administrator to validate your account after reviewing your credentials. This process will take 24 hours. Thank you! Do not close this website without getting logged out. Automatically loggedout in {{time}} seconds.
      v-layout.row-wrap.justify-end
        v-flex.shrink
          v-btn.mt-4(color="primary", @click="logout()") Logout
    div(v-else).pa-7.pb-0
      v-alert(
        border="left"
        icon="mdi-alert-circle"
        color="primary"
        text
        ) <strong>User Registration Advisory.</strong> Please be informed that we are only accepting registration requests from <strong> enrolled UPD College of Science (CS) Students, CS Faculty </strong> and <strong> students from other colleges with CS subjects.</strong> Thank you.
      v-form(ref="form")
        div
          v-card-title.mb-1 Personal Information
          v-card-text First Name 
          v-text-field.mx-3.mt-n2(
            v-model.trim="name1",
            rounded
            filled
            dense,
            placeholder="Type first name",
            required,
            :rules="[(v) => !!v || 'First Name is required.']"
          )
          v-card-text Middle Name 
          v-text-field.mx-3.mt-n2(
            v-if="noMiddleName==false"
            v-model.trim="name2",
            rounded
            filled
            dense,
            placeholder="Type middle name",
            required,
            :rules="[(v) => !!v || 'Middle Name is required.']"
          )
          v-checkbox.mx-3.mt-n2(v-model="noMiddleName")
            template(v-slot:label) 
              span.mb-n2 No middle name
          v-card-text Last Name 
          v-text-field.mx-3.mt-n2(
            v-model.trim="name3",
            rounded
            filled
            dense,
            placeholder="Type last name",
            required,
            :rules="[(v) => !!v || 'Last Name is required.']"
          )
          v-card-text.mt-n2 Student / Employee Number
          v-text-field.mx-3.mt-n2(
            v-model.trim="number",
            rounded
            filled
            dense,
            placeholder="Type student or employee number",
            :rules="[(v) => !!v || 'Student/Faculty number is required.', (v) => v.length <= 9 || 'Please type atmost 9 characters. Do not include dash.']",
            required
          )
          v-card-text.mt-n2 UP Mail
          v-text-field.mx-3.mt-n2(v-model="email", rounded filled dense :rules="[v => !!v || 'E-mail is required']" readonly)
          //- ,v => /.+@up.edu.ph+/.test(v) || 'Must be UP mail'
        div 
          v-card-title.mb-1 Academic Information
          v-card-text.mt-n2 Type
          v-select(
            v-model="type"
            rounded 
            filled
            dense
            :rules="[(v) => !!v || 'Type is required. Please select one.']"
            @change="typeChange"
            :items="typeSelect"
            placeholder="Select user type"
            item-text="item"
            item-value="value"
          ).ml-3.mt-n1
          v-radio-group.ml-3.mt-n1(
              v-model="type"
              :rules="[(v) => !!v || 'Type is required. Please select one.']"
              required
              @change="typeChange"
            )
          div(v-if="type!='staff'")
            v-card-text.mt-n2 College Unit
            v-autocomplete(
              v-if="type!='staff'"
              :items="colleges"
              rounded
              filled
              dense
              v-model="college"
              placeholder="Type and select college"
              required
              :rules="[(v) => !!v || 'College is required. Please select one.']"
              ).mt-n2.mx-3
            div(v-if="college=='College of Science'")
              v-card-text.mt-n2 Institute
              v-autocomplete(
                v-if="type!='staff'"
                :items="institutes"
                rounded
                filled
                dense
                v-model="institute"
                item-text="name"
                item-value="name"
                placeholder="Type and select institute"
                required
                :rules="[(v) => !!v || 'Institute is required. Please select one.']"
                ).mt-n2.mx-3
            v-card-text(v-if="type!='staff'").mt-n2.mb-n4 Courses Taking (e.g. Math 2, Chem 16, MS 101)
            v-list(v-if="type!='staff'")
              v-list-item(
              v-for="item, i in courses"
              :key="i"
              )
                v-list-item-icon
                  v-icon mdi-arrow-right-bottom-bold
                v-list-item-action.text-muted.mr-1 Course {{i+1}}:
                v-list-item-content
                  v-list-item-title(v-text="item")
                v-list-item-action
                  v-btn(color="error" x-small @click="deleteCourse(i); course=''; lastCourse=''; entries=[]" :disabled="loading") remove
            v-text-field(
              v-if="type!='staff'"
              v-model.trim="course"
              placeholder="Search Course"
              rounded
              filled
              dense
              @keyup.enter="search()"
              prepend-inner-icon="mdi-magnify"
              ).mx-3
              template(v-slot:append)
                v-btn(
                color="primary"
                small
                rounded
                @click.stop="search()"
                ).mt-n1.mr-n3 Search
            div.caption.muted.mt-n5.text-center.mx-3 Please type the exact course code. This is case and space sensitive. Click among the course chips below to add the course.
            div.text-center.mx-3
              div.my-3.subtitle-2 Search query should be atleast 4 characters. Click "Search" button or Enter to search.
              div.my-3 {{notify}}
              div(v-if="isLoading")
                v-progress-circular(
                  indeterminate
                  color="primary"
                  ).mt-3
              span(v-for="entry, i in entries" :key="i" v-else)
                v-chip(
                  class="ma-2"
                  color="primary"
                  outlined
                  @click="addCourse(i, entry.course)"
                )
                  v-icon(left) mdi-plus-circle
                  span {{entry.course}}
          div.mt-5
            div.pb-4
              v-card-title User Agreement
              v-card-text.text-justify I, {{ `${this.firstName}${this.middleName}${this.name3}` }}, acknowledge that I have read the Acceptable Use Policy of CS Libraries eBook Hub.
              div.ml-3.mb-3.d-flex.align-center
                v-icon(
                  v-if="!agreement"
                  @click="dialog = true",
                  color="grey"
                ) mdi-checkbox-blank-outline
                v-icon(
                  v-else
                  color="primary"
                ) mdi-checkbox-marked
                div.ml-2 Acceptable Use Policy

              v-dialog(v-model="dialog", app, max-width="600", persistent scrollable)
                v-card(tile)
                  v-card-title.headline.grey.lighten-2 Acceptable Use Policy
                  v-card-text(style="height: 600px;")
                    div.text-caption.font-weight-regular.ma-4 Note: After reading the Acceptable Use Policy, please click the "Agree" button found at the bottom of this dialog box. 
                    h5.ma-4 Overview
                      h6.font-weight-regular.px-2.pb-3.pt-2 This policy is set to establish a sensible conduct in utilizing the CSLib eBook Hub. It aims to nurture an existing community of ethical, accountable, and responsible users. Further, this will help avoid overstepping the boundaries of the existing rules of Intellectual Property and Fair Use.
                    h5.ma-4 Scope
                      h6.font-weight-regular.px-2.pb-3.pt-2 This policy applies to all registered Users.
                    h5.ma-4 Users
                      h6.font-weight-regular.px-2.pb-3.pt-2 1. Students
                        v-card-subtitle.font-weight-regular.px-2.pb-2 1.1 Undergraduate
                        v-card-subtitle.font-weight-regular.px-2.pb-2 1.1 Graduate
                        v-card-subtitle.font-weight-regular.px-2.pb-2 1.1 Post Graduate
                      h6.font-weight-regular.px-2.pb-3.pt-2 2. Faculty
                        v-card-subtitle.font-weight-regular.px-2.pb-2 2.1 Permanent Status
                        v-card-subtitle.font-weight-regular.px-2.pb-2 2.1 Temporary Status
                    h5.ma-4 Access
                      h6.font-weight-bold.px-2.pb-3.pt-2 Access is granted through registration. Requirement/s are as follows:
                        h6.font-weight-regular.px-2.pb-3.pt-2 1. Students
                          v-card-subtitle.px-2.py-2 • Must be currently enrolled.
                          v-card-subtitle.px-2.py-2 • UP Mail Account
                        h6.font-weight-regular.px-2.pb-3.pt-2 2. Faculty
                          v-card-subtitle.font-weight-bold.px-2.py-2 2.1 Permanent Faculty
                            v-card-subtitle.px-2.py-2 • UP Mail Account
                          v-card-subtitle.font-weight-bold.px-2.py-2 2.2 Temporary Faculty
                            v-card-subtitle.px-2.py-2 • Most recent appointment
                            v-card-subtitle.px-2.pt-2.mb-n10 • UP Mail Account
                    h5.ma-4 Acceptable Use
                      h6.font-weight-regular.px-2.pb-3.pt-2 1. Use of any device capable of accessing the database anytime and anywhere.
                    h5.ma-4.text-justify Unacceptable Use
                      h6.font-weight-regular.px-2.pb-3.pt-2 1. Sharing of account - giving or lending your username and password to others.
                      h6.font-weight-regular.px-2.pb-2.pt-2 2. Download by any means (third party programs, etc.)
                      h6.font-weight-regular.px-2.pb-2.pt-2 3. Cheating.
                      h6.font-weight-regular.px-2.pb-2.pt-2 4. Hack attempts.
                      h6.font-weight-regular.px-2.pb-2.pt-2 5. Not logging out after use.
                      h6.font-weight-regular.px-2.pb-2.pt-2 6. Commercial use.
                      h6.font-weight-regular.px-2.pb-2.pt-2 7. Other uses aside from academic purpose.
                    h5.ma-4 Sanctions
                      h6.font-weight-regular.px-2.pb-3.pt-2 Any proven violation of this policy will result in <strong>indefinite suspension of account</strong>.
                    h6.font-weight-light Sponsored by the <em> College of Science, University of the Philippines Diliman </em>    
                    v-divider
                    v-card-actions
                      v-layout.justify-end
                        v-flex.shrink
                          v-btn(
                            @click="(agreement = true), (dialog = false)",
                            color="primary"
                          ) Agree
          div.mx-n7
            v-divider
          v-layout.justify-end.py-5.pt-2
            v-flex.shrink
              v-btn(
                color="white",
                @click="logout()"
                :loading="loading1"
              ).mr-3 Logout
              v-btn(
                color="primary",
                @click="updateUser()"
                :loading="loading"
                :disabled="agreement == false"
              ) Submit
         
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";
let updateUser = firebase.functions().httpsCallable("updateUser2");
let getUser = firebase.functions().httpsCallable("getUser");
// import { openDB } from "idb";
import colleges from "@/colleges.js";
import syllabi from "@/syllabi.js";
import { db } from "../main";

export default {
  data() {
    return {
      name1: "",
      name2: "",
      name3: "",
      noMiddleName: false,
      type: "",
      number: "",
      college: "",
      institute: "",
      email: this.$store.state.userToken.email,
      loading: false,
      loading1: false,
      agreement: false,
      dialog: false,
      userId: this.$store.state.userToken.user_id,
      user: [],
      colleges: colleges.colleges,
      institutes: syllabi.institutes,
      courseCatalog: colleges.courses,
      dataReady: true,
      waiting: false,
      time: 60,
      course: "",
      courses: [],
      entries: [],
      isLoading: false,
      notify: "",
      lastCourse: "",
      allCapsCourse: ["ms", "mse", "ge", "mbb"],
      typeSelect: [
        {
          item: "Student",
          value: "student",
        },
        {
          item: "Faculty",
          value: "faculty",
        },
        {
          item: "Staff",
          value: "staff",
        },
      ],
    };
  },
  computed: {
    institution() {
      if (this.college == "College of Science") {
        return this.institute;
      }
      return this.college;
    },
    firstName() {
      return this.name1 + " ";
    },
    middleName() {
      if (this.noMiddleName == true) {
        return "";
      }
      return this.name2 + " ";
    },
  },
  methods: {
    async search() {
      if (this.course.length < 4) return;
      if (this.lastCourse == this.course.toLowerCase()) return;
      this.lastCourse = this.course.toLowerCase();
      if (
        this.allCapsCourse.includes(this.course.split(" ")[0].toLowerCase())
      ) {
        const capitalAll = this.course.toUpperCase();
        await this.searchCourses(capitalAll);
      } else {
        const capitalFirst =
          this.course[0].toUpperCase() + this.course.substring(1);
        await this.searchCourses(capitalFirst);
      }
    },
    async searchCourses(query) {
      this.isLoading = true;
      const dataBase = await db
        .collection("courseReferences2")
        .orderBy("course")
        .startAt(query)
        .endAt(query + "\uf8ff")
        .limit(5);
      const dbResults = await dataBase.get();
      var entries = [];
      dbResults.forEach((doc) => {
        if (!this.courses.includes(doc.data().course)) {
          const data = {
            course: doc.data().course,
          };
          entries.push(data);
        }
      });
      this.isLoading = false;
      this.entries = entries;
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
      if (this.entries.length == 0) {
        this.notify = "No course found. Please enter the correct course code.";
        this.lastCourse = "";
      } else {
        this.notify = "";
      }
    },
    addCourse(i, course) {
      this.courses.push(course);
      this.entries.splice(i, 1);
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
      this.$store.dispatch("setSnackbar", {
        text: course + " added to your courses!",
        icon: "mdi-check",
        iconColor: "success",
      });
    },
    async getUser(id) {
      this.dataReady = false;
      await getUser({ id })
        .then((res) => {
          if (res.data.user != null) {
            this.waiting = res.data.user.waiting;
          }
        })
        .then(() => {
          if (this.waiting == true) {
            this.autoLogout();
            this.timer();
          }
        });
      this.dataReady = true;
    },
    timer() {
      if (this.time != 0) {
        setTimeout(() => {
          this.time -= 1;
          this.timer();
        }, 1000);
      }
    },
    deleteCourse(i) {
      this.courses.splice(i, 1);
    },

    typeChange() {
      if (this.type == "staff") {
        this.courses = ["Staff"];
      } else {
        this.courses = [];
      }
    },
    async updateUser() {
      if (this.$refs.form.validate() && this.courses.length >= 1) {
        this.loading = true;
        await updateUser({
          name: `${this.firstName}${this.middleName}${this.name3}`,
          type: this.type,
          number: this.number,
          email: this.email,
          id: this.userId,
          valid: false,
          waiting: true,
          institution: this.institution,
          courses: this.courses,
        }).then(() => {
          this.getUser(this.userId);
          this.waiting = this.user.waiting;
          this.loading = false;
        });
      } else {
        this.$store.dispatch("setSnackbar", {
          text: "  Please fill the required fields correctly!",
          icon: "mdi-block-helper",
          iconColor: "error",
        });
      }
    },
    autoLogout() {
      setTimeout(() => {
        firebase.auth().signOut();
        this.$store.dispatch("setSnackbar", {
          text: "You have successfully logged out!",
          icon: "mdi-check",
          iconColor: "success",
        });
      }, 60000);
    },
    logout: function () {
      this.loading1 = true;
      firebase.auth().signOut();
      this.loading1 = false;
    },
  },
  async created() {
    this.getUser(this.userId);
  },
};
</script>

<style scoped>
.v-input--selection-controls__input {
  margin: 4px;
}
.covered {
  background: rgba(2, 0, 115, 0.8);
}
</style>
